.certificate-container {
    font-family: Arial, sans-serif;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f4f8;
}

.certificate-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
}

.content-wrapper {
    display: flex;
    gap: 30px;
}

.form-section,
.preview-section {
    flex: 1;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
    color: #34495e;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group-title {
    font-weight: bold;
    color: #34495e;
    margin-bottom: 10px;
}

.form-row {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.form-column {
    flex: 1;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    color: #7f8c8d;
}

.form-input,
.form-select,
.form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.form-textarea {
    min-height: 100px;
    resize: vertical;
}

.button-group {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: white;
}

.btn-primary {
    background-color: #3498db;
}

.btn-success {
    background-color: #2ecc71;
}

.btn-danger {
    background-color: #e74c3c;
}

.preview-canvas {
    width: 100%;
    height: auto;
    border: 1px solid #bdc3c7;
}

.coordinate-display {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.modal-title {
    margin-bottom: 15px;
    color: #34495e;
}

.signature-container {
    border: 1px solid #bdc3c7;
    margin-bottom: 15px;
}

.signature-preview {
    width: 100px;
    height: 50px;
    border: 1px dashed #bdc3c7;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #95a5a6;
}

@media (max-width: 1024px) {
    .content-wrapper {
        flex-direction: column;
    }

    .form-row {
        flex-direction: column;
        gap: 10px;
    }
}
